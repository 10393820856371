import React from "react";
import Layout from "../../components/layout";
import "../../css/pages/auth.scss";
import AuthType from "../../components/auth/AuthType";
import { Helmet } from "react-helmet";

function AuthSignupPage(props) {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <Helmet>
        <script src="/metapixel.js" />
      </Helmet>
      <main
        className="wrapper main-data-container"
        style={{ padding: 0, margin: 0, maxWidth: "100%", width: "100%" }}
        role="main"
        data-datocms-noindex
      >
        <AuthType type={"signup"} providers={["google", "facebook"]} />
      </main>
    </Layout>
  );
}

export default AuthSignupPage;
